@buttonRGBYellow:                    rgba(218,179,0,1);
@fakeTransparentForSafariHack:       rgba(218,179,0,0);

.svg-wrapper, .svg-wrapper-form {
  height: 40px;
  position: relative;
  width: 210px;
  margin: 0 auto;
  svg, rect {
    height: 100%;
    width: 100%;
  }

  &:hover {
    .shape {
      -webkit-animation: 0.5s draw linear forwards;
      animation: 0.5s draw linear forwards;
    }
  }
}


.float-button__right {
  float: right;
}

.register {
  .svg-wrapper {
    .shape {
      stroke-dasharray: 790;
      stroke-dashoffset: 0;
    }
  }
}

.active-button-state {
   background-color: @core-enq-button;
  .animate-draw {
    animation: 0.5s draw linear forwards;
  }
  // &:hover {
    .shape {
      fill: @core-enq-button;
    }
    .text {
      color: @core-white;
    }
  // }
}
.register-anchor {
  &:hover, &:active, &:focus {
    text-decoration: none;
  }
}


.shape {
  fill: @fakeTransparentForSafariHack;

  stroke-dasharray: 195 570;
  stroke-dashoffset: -259;
  stroke-width: 2px;
  stroke: @core-enq-button;
  transition: 1s all ease;
}

.home .vegas-home {
  .register-anchor {
    .shape {
      transition-delay: 0.2s;
      backface-visibility: hidden;

    }
    &:hover {
      .shape {
        fill: @buttonRGBYellow;
      }
    }
  }
}

.text {
  color: @core-enq-button;
  position: relative;
  top: -36px;
  text-align: center;
  transition: 1s all ease;
  text-transform: uppercase;
}
.white-text {
  .text {
    color: @core-white;
  }
}

.center-button {
  margin: 0 auto;
}



.svg-wrapper-form {
  margin: 20px auto;
  .shape {
    stroke: @core-color__default;
    stroke-dashoffset: -200;
    stroke-dasharray: 155 570;
  }
  .text {
    color: @core-color__default;
  }
}

.floorplan-button {
  width: 125px;
  left: 15px;

  .shape {
    stroke-dasharray: 125 570;
    stroke-dashoffset: -166px;
  }
}

@keyframes draw {
  0% {
    stroke-dasharray: 140 540;
    stroke-dashoffset: -454;
    stroke-width: 8px;
  }
  100% {
    stroke-dasharray: 760;
    stroke-dashoffset: 0;
    stroke-width: 2px;
  }
}




// sm styles
@media (max-width: 992px) {
  .svg-wrapper-form {
    .shape {
      stroke-dashoffset: -210;
    }
  }
}

/* ipad Landscape only*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  .svg-wrapper-form {
    .shape {
      stroke-dashoffset: -202;
    }
  }
}


// xs styles
@media (max-width: 767px) {
  .home {
    .shape{
      stroke-dasharray: 760;
      stroke-dashoffset: 0;
    }
  }
  .svg-wrapper-form {
    .shape {
      // stroke-dashoffset: -200;
      stroke-dasharray: 760;
      stroke-dashoffset: 0;
    }
  }

  .svg-wrapper {
    margin: 0 auto;
  }
}


