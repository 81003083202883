// FONTS
@font-family-sans-serif:  'Roboto', "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-serif:       'Prata', Georgia, "Times New Roman", Times, serif;
@font-family-monospace:   Monaco, Menlo, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;


// COLOURS
@core-color__default:     #000000;


@core-dropkick-bg:        #d9d9d2;
@core-dropkick-col:       #000000;


@core-green:        #749884;
@core-green__olive: #a2b59e;
@core-green__dark:  #231f20;
@core-enq-button:   #202918;
@core-blue:         #becdcd;
@core-beige:        #dfd8d2;
@core-white:        #ffffff;
@core-black:        #000000;

@core-form-input: #202918;

.bg-beige {
  background-color: @core-beige;
}

.bg-green {
  background-color: @core-green;
}

.bg-green__olive {
  background-color: @core-green__olive;
}

.bg-green__dark {
  background-color: @core-green__dark;
}
.bg-blue {
  background-color: @core-blue;
}

.serif {
  font-family: @font-family-serif;
}

.sans {
  font-family: @font-family-sans-serif;
}

.heading-style {
  text-align: center;
  margin-top:0;
  margin-bottom: 40px;
  line-height: 40px;
}

.copy-style {
  text-align: center;
}

.enquire-wrapper {
  padding: 38px 0;
}
.enquire-wrapper__no-bottom {
  padding-bottom: 0;
}

.top-logo-wrapper  {
  padding: 2% 0;
}
.wrapper-block {
  padding: 5% 0;
}

.wrapper-block__no-top {
  padding-top: 0;
}

.wrapper-block__no-bottom {
  padding-bottom: 0;
}

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
