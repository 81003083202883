
.fancycontent {
  display: none;
  background-color: @core-blue;
}

#tc {
  background-color: @core-blue;
}

.inner-fancy {
  width: 600px;
  height: 600px;
  color: @core-black;
  background-color: @core-blue;
}

.fancylink {
  text-decoration: none;
  color: @core-black;
  &:hover, &:active, &:focus {
    text-decoration: none;
    color: @core-black;
    border-bottom: 1px solid @core-black;
  }
}

@media only screen and (min-width : 320px) and (max-width : 1023px)  {
	.inner-fancy {
		width: 360px;
	}
}