.form-submit-agents {
  padding: 5% 0;
}

.footer-logo-wrapper {
  float: left;
  width: 18%;
  margin-right: 1%;
  svg {
    max-width: 75%;
  }
}

.logo-spacer {
  margin-bottom: 40px;
}

.form-submit-agents {
  // padding: 5
}
.flex-logos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.taranaki {
  svg {
    width: 165px;
    height: 63px;
  }
}
.ta-square {
  svg {
    width: 127px;
    height: 22px;
  }
}
.marhsall {
  svg {
    width: 135px;
    height: 42px;
  }
}
.hub {
  img {
    width: 90px;
  }
}
.west-union {
  img {
    width: 150px;
  }
}
.terms {
  color: @core-black;
  &:hover {
    text-decoration: none;
    border-bottom: 1px solid @core-black;
    color: @core-black;
  }
  &:visited {
    text-decoration: none;
    color: @core-black;
  }
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .footer-logo-wrapper {
    width: 28%;
    margin-right: 0%;
  }
  .taranaki {
    svg {
      width: 60px;
    }
  }
  .ta-square {
    svg {
      width: 70px;
    }
  }
  .marhsall {
    svg {
      width: 90px;
    }
  }
  .hub {
    img {
      width: 30px;
    }
  }
  .west-union {
    img {
      width: 75px;
    }
  }
  .footer-viewings__mobile {
    margin-top: 40px;
  }
  .flex-logos {
    
  }
}

// xs styles
@media (max-width: 767px) {

}