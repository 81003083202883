.wrapper-thankyou, .wrapper-popup-form {
	position: fixed;
	background-color: @core-blue;
	box-shadow: 2px 2px 15px @core-green__dark;
	border: 1px solid @core-green__dark;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1000;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	padding: 40px;
	max-width: 440px;
}

.wrapper-popup-form {
	transition: all 1ms ease-in;
	max-width: 80vw;
	max-height: 90vh;
	min-height: 80vh;
	overflow: auto;
	bottom: 0px;
	top: -100vh;
}

.animate-form-in {
	top: 50%;
}

.wrapper-thankyou__hide, .wrapper-popup-form__hide {
	display: none;
}

.wrapper-close {
	width: 30px;
	height: 30px;
	// padding: 10px;
	// float: right;
	position: absolute;
	right: 10px;
	top: 10px;
}

.heading-thankyou {
	padding: 50px;
}

.heading__no-top {
  margin-top: 0;
  padding-top: 0;
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
	.wrapper-thankyou {
		width: 80%;
		padding: 0px;
		max-width: auto;
	}
	.wrapper-popup-form {
		min-height: 90vh;
	}
}

// xs styles
@media (max-width: 767px) {

}
