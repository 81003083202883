.header-home-text {
  color: @core-white;
  text-align: center;
  position: relative;
  top: -50%;
  transform: translateY(50%);
  p {
    margin: 0;
  }
}

.header-home-text__bold {
  font-weight: 500;
}

.hero-wrapper {
  img {
    max-width: 100%;
  }
}
.heading-wrapper {
  background-color: @core-beige; 
  width: 100%;
  text-align: center;
  h4,h5 {
    color: @core-green__dark;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: @font-family-serif;
  }
}

.down-arrow-wrapper {
  width: 20px;
  margin: 0 auto 20px auto;
  svg {
    max-width: 100%;
  }
}

.logo-wrapper {
  width: 200px;
  height: 86px;
  margin: 0 auto;
}


// md styles
@media (max-width: 1199px) {
}

// sm styles
@media (max-width: 991px) {
  .mobile-address  {
    margin-top: 30px;
    color: @core-black;
  }
  .heading-wrapper {
    h4,h5 {
      font-size: 13px;
    }
  }
}

// xs styles
@media (max-width: 767px) {

}

