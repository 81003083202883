@labelCol: @core-black;

@inputCol: @core-black;
@backgroundCol: transparent;

@formFontSize: 1.2em;

@submitCol: #f04152;
@submitBg: #FFF;

@borderCol: @inputCol;

.border {
  margin-top: 10px;
}
.form-holder {
  width: 350px;
  float: left;
}
.error {
  color: red !important;
}
.form-title {
  width: 100%;
  color: @labelCol;
  height: 33px;
  padding: 7px 4px 0px 6px;
  margin-bottom: 2px;
}
.text-input,
.custom-select,
.custom-submit,
.custom-textarea {
  border-radius: 0px;
  appearance: none;
  height: auto;
  width: 100%;
  border: 0;
  font-size: @formFontSize;
  font-family: @font-family-base;
  font-weight: 300;
  letter-spacing: 0.05em;
  background-color: @backgroundCol;
  color: @inputCol;
  outline: none;
  padding: 2px;
  padding-left: 0;
  font-size: 0.9em;
}

.custom-textarea {
  height: 100px;
}

.custom-select {
  padding-top: 4px;
  height: 28px;
  box-sizing: border-box;
  background: url("../img/core/arrow.png") 97% 50% no-repeat @backgroundCol;
}

.custom-submit {
  color:@submitCol;
  box-shadow: none;
  margin-top: 10px;
  font-size:  14px;
  text-align: center;
  transition: 0.5s ease;
  border: 1px solid @core-form-input;
  background-color: transparent;
  color: @core-form-input;
  width: 50%;
  margin: 0 auto;
  float: none;
  display: block;
  padding: 10px 5px;
  text-transform: uppercase;
  font-weight: normal;
  &:hover {
     background-color: @core-form-input;
     color: @core-white;
  }
}


.form-container {
  width: 80%;
  margin: 0 auto;
}
.form-container h4 {
  color: #fff;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 20px;
}
label {
  color: @labelCol;
  font-weight: normal;
  font-size: 1.2em;
}
::placeholder {
  color: #767676;
}
option {
  color: #000000 !important;
}

.customSelectInner {
    width: 100% !important;
}

.form-field-left-half, .form-field-right-half, .form-field-full {
  float: left;
  width: 50%;
  width: ~"calc(50% - 20px)";
  height: 50px;
  border-bottom: 1px solid @core-black;
  color: @core-black;
  margin-bottom: 50px;
}
.form-field-right-half {
  float: right;
}
// .form-field-left-half {
//   width: ~"calc(50% - 10px)";
// }
// .form-field-right-half {

// }
.form-field-full {
  width: 100%;
}
.form-field{
  clear: both;
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  label {
    display: none;
  }
  .form-field-left-half,
  .form-field-right-half {
    width: 100%;
    height: 37px;
    margin-bottom: 40px;
  }
}

// xs styles
@media (max-width: 767px) {

}
