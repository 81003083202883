.royalSlider {
    width: 100%;
    //height: 400px;
    position: relative;
    direction: ltr;

    .rsBullets {
        position: relative;
        z-index: 35;
        left: 0;
        bottom: 50px;
        width: 100%;
        height: 20px;
        margin: 0 auto;
        background: transparent;
        text-align: center;
        line-height: 8px;
        overflow: hidden;
    }

    .rsBullet {
        width: 8px;
        height: 8px;
        display: inline-block;
        padding: 6px 11px 6px;
        span {
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #fff;
        }
        &.rsNavSelected {
            span {
                background-color: @core-black;
                border: 1px solid @core-black;
            }
        }
    }



    .rsSlide {
        img {
            opacity: 0.45 !important;
            transition: all 0.3s ease-out;
            transform: scale(1);
        }
    }

    .rsActiveSlide {
        img {
            opacity: 1 !important;
            transform: scale(1);
        }
    }
}

.rsDefault {
    &.rsHor {
        .rsArrowRight,
        .rsArrowLeft {
            .rsArrowIcn {
                background-color: transparent;
                width: 44px;
                height: 30px;
                background-position: top left;
                background-repeat: no-repeat;
                margin:0;
            }
        }
        .rsArrowLeft {
            .rsArrowIcn {
                background-image: url('/img/sliders/rsSlider_arrowLeft.png');
                left: 0;
            }
        }
        .rsArrowRight {
            .rsArrowIcn {
                background-image: url('/img/sliders/rsSlider_arrowRight.png');
                right: 0;
                left: auto;
            }
        }
    }

    .rsOverflow {
        background-color: transparent;
    }
    .rsGCaption {
        background-color: transparent;
        font-size: 14px;
        //bottom: -40px;
        font-family: @font-family-sans-serif;
        //color: @core-black;
        color: @core-white;
        //width: 50%;
    }
    .caption-rs {
        left: 50%;
    }    
}


#floorplan-slider, #floorplan-slider-mob {
    &.rsHor {
        .rsArrowLeft {
            .rsArrowIcn {
                background-image: url('/img/sliders/rsSlider_arrowLeft-black.png');
            }
        }
        .rsArrowRight {
            .rsArrowIcn {
                background-image: url('/img/sliders/rsSlider_arrowRight-black.png');
            }
        }
    }

    .rsOverflow {
        background-color: transparent;
    }
}

.rsDefault,
.rsSlide {
    background-color: @core-green__olive;
}


.sliderContainer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.rsGCaption {
    margin-bottom: 0;
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
    .rsDefault {
        &.rsHor {
            .rsArrowLeft {
                .rsArrowIcn {
                    background-image: url('/img/sliders/rsSlider_arrowLeft.png');
                    background-size:50%;
                    background-position: top left;
                    // left: 40px;
                }
            }
            .rsArrowRight {
                .rsArrowIcn {
                    background-image: url('/img/sliders/rsSlider_arrowRight.png');
                    background-size:50%;
                    background-position: top right;
                    // right: 50px;
                }
            }
        }

        .rsOverflow {
            background-color: transparent;
        }
    }
}

// xs styles
@media (max-width: 767px) {
}